import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__ed5440b3-3532-4be3-afee-0e417e329666'
    x={0}
    y={0}
    viewBox='0 0 500 360'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.banner_svg__st4{fill:#d9eaff}.banner_svg__st7{opacity:.5}.banner_svg__st8{fill:#6f8b99}.banner_svg__st124{fill:#fff}'
      }
    </style>
    <linearGradient
      id='banner_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={203.06}
      y1={1007.176}
      x2={384.39}
      y2={1007.176}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M203.56 331.83l43.29 3.92c1.26 0 2.5.33 3.6.96l36.79 21.08c3.45 1.98 7.7 1.98 11.15 0l85.5-48.78'
      fill='none'
      stroke='url(#banner_svg__SVGID_1_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={239.44}
      y1={727.712}
      x2={344.34}
      y2={727.712}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M343.84 72.9l-33.09-19.22c-1.66-.97-3.56-1.48-5.48-1.48h-15.44c-1.91 0-3.79-.5-5.45-1.46l-22.64-13.07a9.087 9.087 0 00-9-.04l-12.8 7.2'
      fill='none'
      stroke='url(#banner_svg__SVGID_2_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={260.767}
      y1={868.707}
      x2={309.062}
      y2={860.868}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M281.95 189.51l-3.37-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.09.46v3.15c.01.05.04.1.08.13l7.95 4.59c.13.07.29.07.41 0l10.46-6c.05-.02.08-.07.08-.13v-3.16l-4.66.33z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <linearGradient
      id='banner_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={252.59}
      y1={869.176}
      x2={291.183}
      y2={859.321}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M267.62 190.6c.01.05.04.1.08.13l7.95 4.59c.13.07.29.07.41 0l10.47-6.03c.05-.03.08-.08.08-.13 0-.06-.03-.11-.08-.13l-7.95-4.59a.426.426 0 00-.41 0l-10.47 6.03c-.05.02-.08.07-.08.13z'
      fill='url(#banner_svg__SVGID_4_)'
    />
    <path
      className='banner_svg__st4'
      d='M271.72 190.04c0 .03.02.06.04.07l4.5 2.57c.07.05.16.05.23 0l5.87-3.38c.03-.01.05-.04.05-.07-.01-.03-.03-.06-.05-.07l-4.46-2.57a.195.195 0 00-.23 0l-5.88 3.38a.09.09 0 00-.04.08l-.03-.01z'
    />
    <linearGradient
      id='banner_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={277.128}
      y1={809.557}
      x2={276.534}
      y2={1003.068}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M282.33 127.22l-4.46-2.57a.235.235 0 00-.23 0l-5.88 3.39a.09.09 0 00-.04.08c0 .34-.27 61.76 0 61.91 0 .03.02.06.04.07l4.5 2.57c.07.05.16.05.23 0l5.87-3.38c.03-.01.05-.04.05-.07v-61.91c0-.05-.03-.08-.08-.09z'
      fill='url(#banner_svg__SVGID_5_)'
    />
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={277.291}
      y1={807.856}
      x2={276.193}
      y2={908}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M279.44 132.03l-2.06-1.22a.078.078 0 00-.11 0l-2.7 1.56v57.41l2.06 1.19c.03.02.07.02.11 0l2.7-1.57v-57.37z'
      opacity={0.5}
      fill='url(#banner_svg__SVGID_6_)'
    />
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M74.29 283.11c-.28 0-.55-.07-.79-.2l-33.98-19.6a.798.798 0 01-.4-.63v-.07c.01-.28.16-.53.4-.67l25.78-14.89c.5-.29 1.11-.29 1.61 0l33.97 19.58c.17.09.3.25.36.44v.26c0 .28-.15.53-.4.67l-25.75 14.91c-.25.14-.52.2-.8.2zm-8.19-35.76c-.2 0-.39.05-.56.14l-25.78 14.89c-.08.05-.13.13-.14.23-.01.11.04.21.14.26l33.97 19.58c.34.2.77.2 1.11 0l25.78-14.86c.07-.04.12-.12.14-.2a.25.25 0 00-.14-.25l-33.97-19.65c-.16-.09-.35-.14-.55-.14z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M425.47 350.84c-.28 0-.56-.07-.8-.22l-33.97-19.61a.8.8 0 01-.4-.64v-.06c0-.28.15-.53.4-.67l25.78-14.85c.5-.29 1.11-.29 1.61 0l33.97 19.58c.17.1.3.25.36.44v.25c-.01.28-.16.53-.4.68l-25.78 14.89c-.23.14-.49.21-.77.21zm-8.17-35.76c-.2 0-.39.05-.56.14L391 330.12c-.08.05-.13.13-.14.23-.01.11.04.21.14.26l33.97 19.58c.34.2.77.2 1.11 0l25.78-14.89c.07-.04.12-.12.14-.2a.25.25 0 00-.14-.25l-33.97-19.58c-.17-.12-.38-.19-.59-.19z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M123.72 285.51c-.18 0-.36-.05-.51-.14l-18.92-10.92a.56.56 0 01-.18-.78c.05-.07.11-.14.18-.18l14.42-8.31c.31-.18.7-.18 1.01 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.27.47l-14.42 8.31c-.15.09-.33.13-.51.13zm-19.2-11.5l18.92 10.93c.16.09.35.09.5 0l14.43-8.34-18.92-10.93a.497.497 0 00-.5 0l-14.42 8.31-.14.3.13-.27z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M447.36 106.18c-.18 0-.35-.05-.5-.14l-18.92-10.92a.594.594 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.31-.18.7-.18 1.01 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.28.47l-14.42 8.31c-.16.08-.34.12-.51.13zm-19.21-11.51l18.92 10.93c.16.09.35.09.5 0L462 97.25l-18.91-10.97a.497.497 0 00-.5 0l-14.42 8.31-.13.3.11-.22z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M30.15 135.57c-.18 0-.35-.04-.5-.13l-18.92-10.93a.553.553 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.93c.18.1.28.29.28.5-.01.19-.11.37-.28.47l-14.42 8.31c-.17.08-.34.12-.52.12zm-19.21-11.5l18.92 10.92c.16.09.35.09.5 0l14.42-8.34v-.05l-18.92-10.92a.497.497 0 00-.5 0l-14.42 8.3-.14.32.14-.23z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M298.22 263.98c-.18 0-.35-.05-.5-.14l-18.92-10.92a.553.553 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.93c.18.1.29.29.28.5-.01.19-.11.37-.28.47l-14.42 8.3c-.17.09-.34.13-.52.13zm-19.21-11.49l18.92 10.92c.16.09.35.09.5 0l14.42-8.34v-.05l-18.92-10.93a.497.497 0 00-.5 0l-14.42 8.31-.13.3.13-.21z'
      />
    </g>
    <g className='banner_svg__st7'>
      <path
        className='banner_svg__st8'
        d='M257.5 145.71c-.18 0-.35-.05-.5-.14l-18.92-10.93a.556.556 0 01-.21-.75.53.53 0 01.21-.21l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.28.47l-14.42 8.31c-.16.09-.34.14-.52.14zm-19.2-11.51l18.92 10.93c.16.09.35.09.5 0l14.42-8.34c.01-.01.01-.03 0-.05l-18.92-10.92a.497.497 0 00-.5 0l-14.42 8.31-.13.3.13-.23z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={517.487}
      y1={796.856}
      x2={-198.709}
      y2={1057.105}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M207.88 297.76l-.47-.9 9.76-5.1a6.45 6.45 0 003.46-5.72V259c0-2.67 1.43-5.14 3.75-6.48l53.22-30.64c1.98-1.14 4.22-1.74 6.5-1.74h24.09c2.12 0 4.21-.56 6.05-1.63l145.93-85a2.87 2.87 0 001.02-3.92c-.26-.45-.64-.81-1.09-1.06l-49.23-26.68.5-.9 49.18 26.69a3.873 3.873 0 011.56 5.25c-.34.63-.85 1.15-1.46 1.51l-145.93 85a13.044 13.044 0 01-6.56 1.8h-24.08c-2.1 0-4.17.55-5.99 1.6l-53.22 30.64a6.513 6.513 0 00-3.23 5.6v27.04a7.433 7.433 0 01-4.01 6.62l-9.75 5.06z'
      fill='url(#banner_svg__SVGID_7_)'
    />
    <linearGradient
      id='banner_svg__SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1={503.735}
      y1={755.728}
      x2={-212.461}
      y2={1015.977}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M204.85 296.34l-.56-.86 5.57-3.6a5.148 5.148 0 002.35-4.33v-21.03a5.18 5.18 0 00-2.52-4.43L70.91 179.61a6.206 6.206 0 01-3.02-5.32v-18.33a6.18 6.18 0 013.25-5.41l89.42-48.19.49.9-89.43 48.16c-1.66.9-2.69 2.62-2.7 4.51v18.33c0 1.82.96 3.5 2.52 4.43l138.78 82.47a6.228 6.228 0 013.02 5.32v21.02c.01 2.1-1.06 4.05-2.82 5.19l-5.57 3.65z'
      fill='url(#banner_svg__SVGID_8_)'
    />
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={124.68}
      y1={832.373}
      x2={124.68}
      y2={832.373}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <linearGradient
      id='banner_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={313.528}
      y1={990.207}
      x2={275.661}
      y2={988.954}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M306.84 312.48l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.91c-.07.04-.11.11-.12.19v8.8c.01.1.08.19.17.23l11.99 6.94c.21.11.46.11.68 0l10.21-5.96c.06-.03.11-.08.12-.15v-8.84a.255.255 0 00-.17-.18z'
      fill='url(#banner_svg__SVGID_10_)'
    />
    <linearGradient
      id='banner_svg__SVGID_11_'
      gradientUnits='userSpaceOnUse'
      x1={307.272}
      y1={986.734}
      x2={283.797}
      y2={985.95}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M307.01 312.7v8.83a.291.291 0 00-.17-.23l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91a.22.22 0 00-.12.16v-8.8c0-.08.05-.15.12-.19l10.21-5.91c.21-.11.46-.11.68 0l11.99 6.94c.1.05.16.14.17.24z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_11_)'
    />
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={307.015}
      y1={985.586}
      x2={283.835}
      y2={984.82}
      gradientTransform='translate(0 -673.033)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M306.84 312.48l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.91c-.07.04-.11.11-.12.19.02.09.09.17.17.21L296 318.8c.21.11.46.11.68 0l10.21-5.92c.07-.03.11-.1.12-.17a.277.277 0 00-.17-.23z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_12_)'
    />
    <g>
      <linearGradient
        id='banner_svg__SVGID_13_'
        gradientUnits='userSpaceOnUse'
        x1={496.069}
        y1={835.767}
        x2={458.211}
        y2={834.514}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M489.39 158.04l-11.99-6.95a.742.742 0 00-.68 0L466.51 157c-.07.03-.12.1-.12.18V166c.01.1.08.19.17.23l11.98 6.95c.21.11.47.11.68 0l10.21-5.91a.22.22 0 00.12-.16v-8.83c0-.11-.07-.2-.16-.24z'
        fill='url(#banner_svg__SVGID_13_)'
      />
      <linearGradient
        id='banner_svg__SVGID_14_'
        gradientUnits='userSpaceOnUse'
        x1={489.818}
        y1={832.302}
        x2={466.347}
        y2={831.526}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M489.56 158.27v8.82a.333.333 0 00-.17-.23l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.92c-.07.03-.11.09-.12.16v-8.81c0-.08.04-.15.12-.18l10.21-5.91c.21-.11.46-.11.68 0l11.99 6.95c.09.04.15.12.17.22z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_14_)'
      />
      <linearGradient
        id='banner_svg__SVGID_15_'
        gradientUnits='userSpaceOnUse'
        x1={489.555}
        y1={831.131}
        x2={466.385}
        y2={830.365}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M489.39 158.04l-11.99-6.95a.742.742 0 00-.68 0L466.51 157c-.07.03-.12.1-.12.18.02.1.08.18.17.22l11.98 6.94c.21.11.47.11.68 0l10.21-5.91c.07-.03.11-.1.12-.17a.265.265 0 00-.16-.22z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_15_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_16_'
        gradientUnits='userSpaceOnUse'
        x1={101.355}
        y1={895.847}
        x2={77.285}
        y2={895.054}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M97.11 219.83l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.07.07-.07.12v5.6c.01.07.05.12.11.15l7.61 4.42c.14.07.3.07.43 0l6.5-3.76c.04-.02.07-.06.07-.11v-5.61a.192.192 0 00-.11-.15z'
        fill='url(#banner_svg__SVGID_16_)'
      />
      <linearGradient
        id='banner_svg__SVGID_17_'
        gradientUnits='userSpaceOnUse'
        x1={97.385}
        y1={893.604}
        x2={82.472}
        y2={893.109}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M97.22 219.94v5.61c-.01-.06-.05-.12-.11-.14l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.07.06-.07.1v-5.6c0-.05.03-.09.07-.12l6.49-3.76c.14-.07.3-.07.43 0l7.62 4.42c.07.04.11.09.11.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_17_)'
      />
      <linearGradient
        id='banner_svg__SVGID_18_'
        gradientUnits='userSpaceOnUse'
        x1={97.224}
        y1={892.907}
        x2={82.497}
        y2={892.42}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M97.11 219.83l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.07.07-.07.12.02.06.05.11.11.14l7.61 4.42c.14.07.3.07.43 0l6.5-3.76c.04-.02.07-.06.07-.11 0-.1-.04-.15-.11-.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_18_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_19_'
        gradientUnits='userSpaceOnUse'
        x1={97.119}
        y1={785.817}
        x2={73.04}
        y2={785.024}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M92.87 109.75l-7.61-4.37a.466.466 0 00-.43 0l-6.5 3.76c-.04.03-.07.07-.07.12v5.61c.01.06.05.12.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.07-.06.07-.1v-5.68a.18.18 0 00-.11-.14z'
        fill='url(#banner_svg__SVGID_19_)'
      />
      <linearGradient
        id='banner_svg__SVGID_20_'
        gradientUnits='userSpaceOnUse'
        x1={93.142}
        y1={783.541}
        x2={78.233}
        y2={783.055}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M92.98 109.88v5.61c0-.05 0-.1-.11-.14l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.04.02-.06.06-.07.1v-5.6c0-.05.03-.09.07-.12l6.5-3.76c.14-.07.3-.07.43 0l7.61 4.42c.06.04.1.09.11.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_20_)'
      />
      <linearGradient
        id='banner_svg__SVGID_21_'
        gradientUnits='userSpaceOnUse'
        x1={92.984}
        y1={782.876}
        x2={78.257}
        y2={782.39}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M92.87 109.75l-7.61-4.37a.466.466 0 00-.43 0l-6.5 3.76c-.04.03-.07.07-.07.12.02.06.05.11.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.07-.06.07-.11 0-.09-.04-.17-.11-.2z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_21_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_22_'
        gradientUnits='userSpaceOnUse'
        x1={315.502}
        y1={853.577}
        x2={291.432}
        y2={852.784}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M311.26 177.56l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.05.02-.07.07-.07.12v5.6c.01.07.05.12.11.15l7.62 4.42c.14.07.3.07.43 0l6.49-3.77c.04-.02.07-.06.07-.1v-5.61c-.01-.07-.05-.13-.11-.15z'
        fill='url(#banner_svg__SVGID_22_)'
      />
      <linearGradient
        id='banner_svg__SVGID_23_'
        gradientUnits='userSpaceOnUse'
        x1={311.529}
        y1={851.37}
        x2={296.616}
        y2={850.874}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M311.34 177.7v5.61a.193.193 0 00-.11-.14l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.77c-.04.02-.07.06-.07.1v-5.6c0-.05.03-.1.07-.12l6.5-3.76c.14-.07.3-.07.43 0l7.61 4.42c.06.03.1.08.11.14z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_23_)'
      />
      <linearGradient
        id='banner_svg__SVGID_24_'
        gradientUnits='userSpaceOnUse'
        x1={311.374}
        y1={850.636}
        x2={296.647}
        y2={850.149}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M311.26 177.56l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.05.02-.07.07-.07.12.02.06.06.1.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.07-.06.07-.11-.01-.07-.05-.13-.11-.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_24_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_25_'
        gradientUnits='userSpaceOnUse'
        x1={53.325}
        y1={940.271}
        x2={101.691}
        y2={932.422}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M74.53 261.06l-3.36-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.11.46v3.15c0 .06.03.11.08.14l7.96 4.6c.13.07.29.07.41 0l10.48-6.05c.04-.03.07-.08.08-.13v-3.16l-4.68.36z'
        fill='url(#banner_svg__SVGID_25_)'
      />
      <linearGradient
        id='banner_svg__SVGID_26_'
        gradientUnits='userSpaceOnUse'
        x1={45.142}
        y1={940.724}
        x2={83.775}
        y2={930.857}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M60.19 262.15c0 .06.03.11.08.14l7.96 4.59c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14.16.16 0 00-.08-.14l-7.96-4.6a.426.426 0 00-.41 0l-10.48 6.08a.16.16 0 00-.08.14v-.03z'
        fill='url(#banner_svg__SVGID_26_)'
      />
      <path
        className='banner_svg__st4'
        d='M64.3 261.59c-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07 0-.03-.02-.06-.05-.08l-4.46-2.58a.195.195 0 00-.23 0l-5.88 3.39c-.01.03-.01.06 0 .08h-.05z'
      />
      <linearGradient
        id='banner_svg__SVGID_27_'
        gradientUnits='userSpaceOnUse'
        x1={69.758}
        y1={881.052}
        x2={69.163}
        y2={1074.804}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M74.93 198.69l-4.46-2.58a.235.235 0 00-.23 0l-5.88 3.4c-.01.03-.01.06 0 .08 0 .34-.27 61.84 0 61.99-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07v-62a.159.159 0 00-.1-.08z'
        fill='url(#banner_svg__SVGID_27_)'
      />
      <linearGradient
        id='banner_svg__SVGID_28_'
        gradientUnits='userSpaceOnUse'
        x1={69.889}
        y1={879.349}
        x2={68.798}
        y2={979.614}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M72.03 203.48l-2.05-1.19c-.03-.02-.07-.02-.11 0l-2.7 1.56v57.49l2.05 1.19c.04.02.08.02.12 0l2.7-1.57-.01-57.48z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_28_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_29_'
        gradientUnits='userSpaceOnUse'
        x1={305.307}
        y1={963.014}
        x2={353.673}
        y2={955.165}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M326.54 283.8l-3.36-1.95a.426.426 0 00-.41 0l-4.48 2.6-6.1.46v3.15c0 .05.03.1.08.13l7.96 4.6c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14v-3.16l-4.66.35z'
        fill='url(#banner_svg__SVGID_29_)'
      />
      <linearGradient
        id='banner_svg__SVGID_30_'
        gradientUnits='userSpaceOnUse'
        x1={297.121}
        y1={963.45}
        x2={335.763}
        y2={953.591}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M312.17 284.88c0 .06.03.11.08.14l7.96 4.59c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14.16.16 0 00-.08-.14l-7.96-4.59a.38.38 0 00-.41 0l-10.48 6.04c-.05.03-.08.09-.08.14z'
        fill='url(#banner_svg__SVGID_30_)'
      />
      <path
        className='banner_svg__st4'
        d='M316.28 284.33c0 .03.02.06.05.07l4.46 2.58c.07.05.17.05.24 0l5.88-3.39c.01-.02.01-.05 0-.07.01-.03.01-.06 0-.08l-4.51-2.58a.235.235 0 00-.23 0l-5.88 3.39c-.03.02-.05.05-.05.09l.04-.01z'
      />
      <linearGradient
        id='banner_svg__SVGID_31_'
        gradientUnits='userSpaceOnUse'
        x1={321.662}
        y1={903.778}
        x2={321.067}
        y2={1097.53}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M326.91 221.43l-4.51-2.58a.195.195 0 00-.23 0l-5.84 3.42c-.03.02-.05.05-.05.08 0 .34-.27 61.84 0 61.99 0 .03.02.06.05.07l4.46 2.58c.07.05.17.05.24 0l5.88-3.39c.01-.02.01-.05 0-.07v-62a.09.09 0 000-.1z'
        fill='url(#banner_svg__SVGID_31_)'
      />
      <linearGradient
        id='banner_svg__SVGID_32_'
        gradientUnits='userSpaceOnUse'
        x1={321.841}
        y1={902.075}
        x2={320.741}
        y2={1002.339}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M324.01 226.24l-2.08-1.26c-.03-.02-.07-.02-.11 0l-2.7 1.56v57.49l2.06 1.19c.03.02.07.02.11 0l2.7-1.56c.01-.01.02-57.23.02-57.42z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_32_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_33_'
        gradientUnits='userSpaceOnUse'
        x1={34.052}
        y1={842.758}
        x2={82.417}
        y2={834.909}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M55.26 163.57l-3.36-1.95a.446.446 0 00-.42 0l-4.42 2.55-6.11.46v3.15c0 .05.03.1.08.13l7.95 4.6c.13.07.29.07.42 0l10.48-6.04c.04-.03.07-.08.08-.14v-3.16l-4.7.4z'
        fill='url(#banner_svg__SVGID_33_)'
      />
      <linearGradient
        id='banner_svg__SVGID_34_'
        gradientUnits='userSpaceOnUse'
        x1={25.868}
        y1={843.204}
        x2={64.501}
        y2={833.345}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M40.92 164.63c0 .06.03.11.08.14l7.95 4.59c.13.08.29.08.42 0l10.48-6.04a.16.16 0 00.08-.14.16.16 0 00-.08-.14l-7.96-4.59a.397.397 0 00-.42 0L41 164.47c-.05.03-.08.09-.08.14v.02z'
        fill='url(#banner_svg__SVGID_34_)'
      />
      <path
        className='banner_svg__st4'
        d='M45.03 164.09c-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07 0-.03-.02-.06-.05-.08l-4.51-2.58a.235.235 0 00-.23 0l-5.88 3.4c-.01.02-.01.05 0 .07z'
      />
      <linearGradient
        id='banner_svg__SVGID_35_'
        gradientUnits='userSpaceOnUse'
        x1={50.428}
        y1={783.535}
        x2={49.833}
        y2={977.286}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M55.65 101.18l-4.51-2.58a.195.195 0 00-.23 0l-5.88 3.39c-.01.03-.01.06 0 .08 0 .35-.27 61.84 0 61.99-.01.02-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.01.04-.04.05-.07v-62c0-.03-.02-.05-.05-.07z'
        fill='url(#banner_svg__SVGID_35_)'
      />
      <linearGradient
        id='banner_svg__SVGID_36_'
        gradientUnits='userSpaceOnUse'
        x1={50.564}
        y1={781.831}
        x2={49.474}
        y2={882.096}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M52.76 106l-2.1-1.23c-.03-.02-.07-.02-.11 0l-2.7 1.57v57.49l2.05 1.19c.03.02.07.02.11 0l2.7-1.56c.01-.01.05-57.27.05-57.46z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_36_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_37_'
        gradientUnits='userSpaceOnUse'
        x1={261.43}
        y1={934.09}
        x2={566.29}
        y2={919.096}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M489.55 300.17c-.02.42-.26.81-.62 1.03l-60.45 34.91c-.98.58-2.2.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13v-93.76-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.05z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_37_)'
      />
      <linearGradient
        id='banner_svg__SVGID_38_'
        gradientUnits='userSpaceOnUse'
        x1={266.973}
        y1={988.747}
        x2={563.094}
        y2={974.185}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M489.54 282.9v17.28c-.02.43-.25.81-.62 1.03l-60.44 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.24-.64-.68-.62-1.14V282.3c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04l-.01.04z'
        fill='url(#banner_svg__SVGID_38_)'
      />
      <linearGradient
        id='banner_svg__SVGID_39_'
        gradientUnits='userSpaceOnUse'
        x1={506.294}
        y1={947.845}
        x2={-121.697}
        y2={1008.758}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M488.91 281.76l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.35.81-1.12.47-1.72-.11-.19-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_39_)'
      />
      <linearGradient
        id='banner_svg__SVGID_40_'
        gradientUnits='userSpaceOnUse'
        x1={487.222}
        y1={965.006}
        x2={435.76}
        y2={997.658}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M436.58 323.41c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07a.41.41 0 01-.22.07z'
        fill='url(#banner_svg__SVGID_40_)'
      />
      <linearGradient
        id='banner_svg__SVGID_41_'
        gradientUnits='userSpaceOnUse'
        x1={487.854}
        y1={965.995}
        x2={436.391}
        y2={998.647}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M480.03 298.35c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
        fill='url(#banner_svg__SVGID_41_)'
      />
      <linearGradient
        id='banner_svg__SVGID_42_'
        gradientUnits='userSpaceOnUse'
        x1={487.946}
        y1={966.137}
        x2={436.483}
        y2={998.79}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M482.02 297.31a.455.455 0 01-.22-.86l.42-.22c.22-.12.49-.04.61.18s.04.49-.18.61h-.01l-.42.23a.36.36 0 01-.2.06z'
        fill='url(#banner_svg__SVGID_42_)'
      />
      <linearGradient
        id='banner_svg__SVGID_43_'
        gradientUnits='userSpaceOnUse'
        x1={488.008}
        y1={966.244}
        x2={436.546}
        y2={998.888}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M483.81 296.32a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23c-.05.04-.13.06-.2.06z'
        fill='url(#banner_svg__SVGID_43_)'
      />
      <linearGradient
        id='banner_svg__SVGID_44_'
        gradientUnits='userSpaceOnUse'
        x1={535.868}
        y1={960.633}
        x2={420.793}
        y2={963.216}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M483.9 266.55v15.74c-.02.38-.23.72-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.19.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_44_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_45_'
          gradientUnits='userSpaceOnUse'
          x1={266.973}
          y1={963.071}
          x2={563.094}
          y2={948.5}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M489.54 257.21v17.28c-.02.42-.25.81-.62 1.02l-60.44 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.04l-.02-.01z'
          fill='url(#banner_svg__SVGID_45_)'
        />
        <linearGradient
          id='banner_svg__SVGID_46_'
          gradientUnits='userSpaceOnUse'
          x1={506.295}
          y1={922.168}
          x2={-121.696}
          y2={983.089}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M488.91 256.08l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62 1v.1c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.6-.34.81-1.1.47-1.7-.1-.21-.27-.37-.47-.48z'
          fill='url(#banner_svg__SVGID_46_)'
        />
        <linearGradient
          id='banner_svg__SVGID_47_'
          gradientUnits='userSpaceOnUse'
          x1={487.228}
          y1={939.339}
          x2={435.766}
          y2={971.982}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M436.58 297.72c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.07a.45.45 0 11.53.73c-.02.02-.05.03-.08.05l-40.01 23.07a.51.51 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_47_)'
        />
        <linearGradient
          id='banner_svg__SVGID_48_'
          gradientUnits='userSpaceOnUse'
          x1={487.822}
          y1={940.267}
          x2={436.359}
          y2={972.919}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M480.03 272.67c-.25.06-.5-.1-.55-.35s.1-.5.35-.55l.42-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.23c-.06.06-.14.09-.22.1z'
          fill='url(#banner_svg__SVGID_48_)'
        />
        <linearGradient
          id='banner_svg__SVGID_49_'
          gradientUnits='userSpaceOnUse'
          x1={487.947}
          y1={940.462}
          x2={436.485}
          y2={973.105}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M482.02 271.62c-.25 0-.45-.2-.45-.45 0-.17.09-.32.24-.4l.42-.23c.22-.12.5-.03.61.19s.03.5-.19.61l-.42.22a.42.42 0 01-.21.06z'
          fill='url(#banner_svg__SVGID_49_)'
        />
        <linearGradient
          id='banner_svg__SVGID_50_'
          gradientUnits='userSpaceOnUse'
          x1={488.01}
          y1={940.561}
          x2={436.548}
          y2={973.213}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M483.81 270.63a.432.432 0 01-.43-.23.46.46 0 01.19-.61l.43-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.06.03-.13.05-.19.05z'
          fill='url(#banner_svg__SVGID_50_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_51_'
        gradientUnits='userSpaceOnUse'
        x1={535.868}
        y1={934.938}
        x2={420.793}
        y2={937.53}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M483.9 240.88v15.74c-.02.38-.23.72-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_51_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_52_'
          gradientUnits='userSpaceOnUse'
          x1={266.973}
          y1={937.682}
          x2={563.094}
          y2={923.12}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M489.54 231.83v17.28c-.01.43-.25.82-.62 1.03l-60.44 34.89c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.19c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.44-34.9c.37-.22.61-.61.62-1.05z'
          fill='url(#banner_svg__SVGID_52_)'
        />
        <linearGradient
          id='banner_svg__SVGID_53_'
          gradientUnits='userSpaceOnUse'
          x1={506.295}
          y1={896.788}
          x2={-121.696}
          y2={957.709}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M488.91 230.7l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.03-.47-.22-.91-.63-1.14z'
          fill='url(#banner_svg__SVGID_53_)'
        />
        <linearGradient
          id='banner_svg__SVGID_54_'
          gradientUnits='userSpaceOnUse'
          x1={487.227}
          y1={913.948}
          x2={435.765}
          y2={946.6}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M436.58 272.34c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.08c.22-.12.49-.04.62.17.12.22.04.49-.17.61l-40.01 23.07c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_54_)'
        />
        <linearGradient
          id='banner_svg__SVGID_55_'
          gradientUnits='userSpaceOnUse'
          x1={487.854}
          y1={914.939}
          x2={436.392}
          y2={947.582}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M480.03 247.29a.47.47 0 01-.4-.23.46.46 0 01.19-.61l.42-.23c.22-.12.5-.03.61.19s.03.5-.19.61l-.42.23c-.06.02-.13.04-.21.04z'
          fill='url(#banner_svg__SVGID_55_)'
        />
        <linearGradient
          id='banner_svg__SVGID_56_'
          gradientUnits='userSpaceOnUse'
          x1={487.957}
          y1={915.088}
          x2={436.494}
          y2={947.74}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M482.02 246.24c-.17 0-.33-.09-.4-.24a.445.445 0 01.19-.6l.42-.22c.22-.12.49-.04.61.18v.01c.12.21.04.48-.18.6 0 0-.01 0-.01.01l-.42.23c-.07.01-.14.03-.21.03z'
          fill='url(#banner_svg__SVGID_56_)'
        />
        <linearGradient
          id='banner_svg__SVGID_57_'
          gradientUnits='userSpaceOnUse'
          x1={488.012}
          y1={915.183}
          x2={436.549}
          y2={947.836}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M483.81 245.25c-.17 0-.33-.09-.41-.24a.457.457 0 01.19-.6l.43-.23a.447.447 0 01.41.79l-.42.23c-.05.03-.13.05-.2.05z'
          fill='url(#banner_svg__SVGID_57_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_58_'
        gradientUnits='userSpaceOnUse'
        x1={535.868}
        y1={909.568}
        x2={420.793}
        y2={912.151}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M483.9 215.51v15.72c-.02.38-.23.72-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.21-.59-.62-.57-1.04v-15.71c0 .41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.05z'
        fill='url(#banner_svg__SVGID_58_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_59_'
          gradientUnits='userSpaceOnUse'
          x1={266.972}
          y1={912.197}
          x2={563.094}
          y2={897.635}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M489.54 206.34v17.26c-.02.43-.25.81-.62 1.03l-60.44 34.92c-.99.57-2.2.57-3.19 0l-61.66-35.46c-.4-.23-.64-.67-.62-1.13v-17.2c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.22.61-.62.62-1.07z'
          fill='url(#banner_svg__SVGID_59_)'
        />
        <linearGradient
          id='banner_svg__SVGID_60_'
          gradientUnits='userSpaceOnUse'
          x1={231.782}
          y1={886.833}
          x2={506.573}
          y2={875.862}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M489.54 206.29v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.22-11.63c-.39-.22-.63-.64-.63-1.09v-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47.1.18.16.39.16.6z'
          fill='url(#banner_svg__SVGID_60_)'
        />
        <linearGradient
          id='banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={487.22}
          y1={888.457}
          x2={435.758}
          y2={921.11}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M436.58 246.86c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62L436.8 246.8c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_61_)'
        />
        <linearGradient
          id='banner_svg__SVGID_62_'
          gradientUnits='userSpaceOnUse'
          x1={487.859}
          y1={889.457}
          x2={436.396}
          y2={922.109}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M480.03 221.81c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.22c.21-.13.49-.07.62.14s.07.49-.14.62c-.02.01-.04.02-.05.03l-.42.22c-.07.03-.14.05-.22.05z'
          fill='url(#banner_svg__SVGID_62_)'
        />
        <linearGradient
          id='banner_svg__SVGID_63_'
          gradientUnits='userSpaceOnUse'
          x1={487.92}
          y1={889.55}
          x2={436.457}
          y2={922.203}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M482.02 220.76c-.25.06-.5-.09-.56-.34s.09-.5.34-.56l.42-.23c.22-.11.49-.03.61.19s.04.49-.18.61h-.01l-.42.23c-.05.05-.12.09-.2.1z'
          fill='url(#banner_svg__SVGID_63_)'
        />
        <linearGradient
          id='banner_svg__SVGID_64_'
          gradientUnits='userSpaceOnUse'
          x1={488.006}
          y1={889.696}
          x2={436.544}
          y2={922.339}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M483.81 219.77a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23c-.05.04-.13.06-.2.06z'
          fill='url(#banner_svg__SVGID_64_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_65_'
        gradientUnits='userSpaceOnUse'
        x1={193.409}
        y1={765.015}
        x2={498.269}
        y2={750.021}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M421.53 131.1c-.02.42-.26.81-.62 1.03l-60.45 34.91c-.98.58-2.2.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13V36.69v-.1c.03-.41.26-.79.62-.99L356.07.7c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.06z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_65_)'
      />
      <linearGradient
        id='banner_svg__SVGID_66_'
        gradientUnits='userSpaceOnUse'
        x1={198.953}
        y1={819.682}
        x2={495.074}
        y2={805.12}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M421.52 113.83v17.28c-.02.43-.25.81-.62 1.03l-60.44 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.24-.64-.68-.62-1.14v-17.21c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04l-.01.03z'
        fill='url(#banner_svg__SVGID_66_)'
      />
      <linearGradient
        id='banner_svg__SVGID_67_'
        gradientUnits='userSpaceOnUse'
        x1={438.274}
        y1={778.775}
        x2={-189.717}
        y2={839.688}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M420.89 112.69l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.35.81-1.12.47-1.72-.11-.19-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_67_)'
      />
      <linearGradient
        id='banner_svg__SVGID_68_'
        gradientUnits='userSpaceOnUse'
        x1={419.202}
        y1={795.936}
        x2={367.739}
        y2={828.588}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M368.56 154.34c-.16 0-.31-.09-.39-.23a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07a.41.41 0 01-.22.07z'
        fill='url(#banner_svg__SVGID_68_)'
      />
      <linearGradient
        id='banner_svg__SVGID_69_'
        gradientUnits='userSpaceOnUse'
        x1={419.834}
        y1={796.925}
        x2={368.371}
        y2={829.577}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M412.01 129.28c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14s.07.49-.14.62c-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
        fill='url(#banner_svg__SVGID_69_)'
      />
      <linearGradient
        id='banner_svg__SVGID_70_'
        gradientUnits='userSpaceOnUse'
        x1={419.926}
        y1={797.067}
        x2={368.463}
        y2={829.72}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M414 128.24a.455.455 0 01-.22-.86l.42-.22c.22-.12.49-.04.61.18s.04.49-.18.61h-.01l-.42.22c-.06.05-.13.07-.2.07z'
        fill='url(#banner_svg__SVGID_70_)'
      />
      <linearGradient
        id='banner_svg__SVGID_71_'
        gradientUnits='userSpaceOnUse'
        x1={419.988}
        y1={797.174}
        x2={368.526}
        y2={829.818}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M415.79 127.25a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.22c-.05.05-.13.07-.2.07z'
        fill='url(#banner_svg__SVGID_71_)'
      />
      <linearGradient
        id='banner_svg__SVGID_72_'
        gradientUnits='userSpaceOnUse'
        x1={467.848}
        y1={791.563}
        x2={352.773}
        y2={794.146}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M415.88 97.48v15.74c-.02.38-.23.72-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V96.95c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.19.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_72_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_73_'
          gradientUnits='userSpaceOnUse'
          x1={198.952}
          y1={794.001}
          x2={495.074}
          y2={779.43}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M421.52 88.14v17.28c-.02.42-.25.81-.62 1.02l-60.44 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.04l-.02-.01z'
          fill='url(#banner_svg__SVGID_73_)'
        />
        <linearGradient
          id='banner_svg__SVGID_74_'
          gradientUnits='userSpaceOnUse'
          x1={438.274}
          y1={753.098}
          x2={-189.716}
          y2={814.019}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M420.89 87.01l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62 1v.1c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.6-.34.81-1.1.47-1.7-.1-.21-.27-.37-.47-.48z'
          fill='url(#banner_svg__SVGID_74_)'
        />
        <linearGradient
          id='banner_svg__SVGID_75_'
          gradientUnits='userSpaceOnUse'
          x1={419.209}
          y1={770.269}
          x2={367.746}
          y2={802.913}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M368.56 128.65c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.07a.45.45 0 11.53.73c-.02.02-.05.03-.08.05l-40.01 23.07a.51.51 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_75_)'
        />
        <linearGradient
          id='banner_svg__SVGID_76_'
          gradientUnits='userSpaceOnUse'
          x1={419.801}
          y1={771.197}
          x2={368.339}
          y2={803.849}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M412.01 103.6c-.25.06-.5-.1-.55-.35-.06-.25.1-.5.35-.55l.42-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.23c-.06.06-.14.09-.22.1z'
          fill='url(#banner_svg__SVGID_76_)'
        />
        <linearGradient
          id='banner_svg__SVGID_77_'
          gradientUnits='userSpaceOnUse'
          x1={419.927}
          y1={771.392}
          x2={368.464}
          y2={804.035}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M414 102.55c-.25 0-.45-.2-.45-.45 0-.17.09-.32.24-.4l.42-.23c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.22a.42.42 0 01-.21.06z'
          fill='url(#banner_svg__SVGID_77_)'
        />
        <linearGradient
          id='banner_svg__SVGID_78_'
          gradientUnits='userSpaceOnUse'
          x1={419.992}
          y1={771.494}
          x2={368.529}
          y2={804.147}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M415.79 101.56a.432.432 0 01-.43-.23.46.46 0 01.19-.61l.43-.22a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.06.02-.13.04-.19.04z'
          fill='url(#banner_svg__SVGID_78_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_79_'
        gradientUnits='userSpaceOnUse'
        x1={467.848}
        y1={765.868}
        x2={352.772}
        y2={768.46}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M415.88 71.81v15.74c-.02.38-.23.72-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V71.27c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_79_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_80_'
          gradientUnits='userSpaceOnUse'
          x1={198.952}
          y1={768.612}
          x2={495.074}
          y2={754.05}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M421.52 62.76v17.28c-.01.43-.25.82-.62 1.03l-60.44 34.89c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14V62.18c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.44-34.9c.37-.22.61-.61.62-1.05z'
          fill='url(#banner_svg__SVGID_80_)'
        />
        <linearGradient
          id='banner_svg__SVGID_81_'
          gradientUnits='userSpaceOnUse'
          x1={438.274}
          y1={727.718}
          x2={-189.716}
          y2={788.639}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M420.89 61.63l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.03-.48-.22-.91-.63-1.14z'
          fill='url(#banner_svg__SVGID_81_)'
        />
        <linearGradient
          id='banner_svg__SVGID_82_'
          gradientUnits='userSpaceOnUse'
          x1={419.207}
          y1={744.878}
          x2={367.745}
          y2={777.53}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M368.56 103.27c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.08c.22-.12.49-.04.62.17.12.22.04.49-.17.61l-40.01 23.07c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_82_)'
        />
        <linearGradient
          id='banner_svg__SVGID_83_'
          gradientUnits='userSpaceOnUse'
          x1={419.832}
          y1={745.865}
          x2={368.37}
          y2={778.509}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M412.01 78.21a.47.47 0 01-.4-.23.46.46 0 01.19-.61l.42-.22c.22-.12.5-.03.61.19s.03.5-.19.61l-.42.23c-.06.02-.13.04-.21.03z'
          fill='url(#banner_svg__SVGID_83_)'
        />
        <linearGradient
          id='banner_svg__SVGID_84_'
          gradientUnits='userSpaceOnUse'
          x1={419.932}
          y1={746.01}
          x2={368.469}
          y2={778.663}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M414 77.17c-.17 0-.33-.09-.41-.24a.445.445 0 01.19-.6l.42-.23c.22-.12.49-.04.61.18v.01c.12.21.04.48-.18.6 0 0-.01 0-.01.01l-.42.23c-.06.02-.13.04-.2.04z'
          fill='url(#banner_svg__SVGID_84_)'
        />
        <linearGradient
          id='banner_svg__SVGID_85_'
          gradientUnits='userSpaceOnUse'
          x1={419.995}
          y1={746.118}
          x2={368.532}
          y2={778.771}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M415.79 76.18c-.17 0-.33-.09-.4-.24a.457.457 0 01.19-.6l.43-.23c.23-.09.49.03.58.26.07.19 0 .41-.17.53l-.42.23c-.06.03-.14.05-.21.05z'
          fill='url(#banner_svg__SVGID_85_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_86_'
        gradientUnits='userSpaceOnUse'
        x1={467.848}
        y1={740.498}
        x2={352.773}
        y2={743.081}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M415.88 46.44v15.72c-.02.38-.23.72-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.21-.59-.62-.57-1.04V45.89c0 .41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.05z'
        fill='url(#banner_svg__SVGID_86_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_87_'
          gradientUnits='userSpaceOnUse'
          x1={198.952}
          y1={743.127}
          x2={495.073}
          y2={728.565}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M421.52 37.27v17.26c-.02.43-.25.81-.62 1.03l-60.44 34.92c-.99.57-2.2.57-3.19 0l-61.66-35.46c-.4-.23-.64-.67-.62-1.13v-17.2c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.22.61-.62.62-1.07z'
          fill='url(#banner_svg__SVGID_87_)'
        />
        <linearGradient
          id='banner_svg__SVGID_88_'
          gradientUnits='userSpaceOnUse'
          x1={163.762}
          y1={717.763}
          x2={438.553}
          y2={706.792}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M421.52 37.22v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.23-11.63c-.39-.22-.63-.64-.63-1.09v-.1c.03-.41.26-.79.62-.99L356.04.7c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47.11.18.17.39.17.6z'
          fill='url(#banner_svg__SVGID_88_)'
        />
        <linearGradient
          id='banner_svg__SVGID_89_'
          gradientUnits='userSpaceOnUse'
          x1={419.204}
          y1={719.394}
          x2={367.742}
          y2={752.047}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M368.56 77.79c-.16 0-.31-.09-.39-.22a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_89_)'
        />
        <linearGradient
          id='banner_svg__SVGID_90_'
          gradientUnits='userSpaceOnUse'
          x1={419.836}
          y1={720.383}
          x2={368.374}
          y2={753.036}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M412.01 52.74c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14s.07.49-.14.62c-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
          fill='url(#banner_svg__SVGID_90_)'
        />
        <linearGradient
          id='banner_svg__SVGID_91_'
          gradientUnits='userSpaceOnUse'
          x1={419.9}
          y1={720.481}
          x2={368.437}
          y2={753.133}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M414 51.69c-.25.06-.5-.09-.56-.34s.09-.5.34-.56l.42-.23a.46.46 0 01.61.19c.12.22.04.49-.18.61h-.01l-.42.23c-.05.05-.12.09-.2.1z'
          fill='url(#banner_svg__SVGID_91_)'
        />
        <linearGradient
          id='banner_svg__SVGID_92_'
          gradientUnits='userSpaceOnUse'
          x1={419.986}
          y1={720.626}
          x2={368.524}
          y2={753.269}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M415.79 50.7a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.41.24c-.06.03-.14.05-.21.05z'
          fill='url(#banner_svg__SVGID_92_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_93_'
        gradientUnits='userSpaceOnUse'
        x1={21.608}
        y1={773.805}
        x2={326.469}
        y2={758.811}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M249.73 139.89c-.02.42-.26.81-.62 1.03l-60.45 34.91c-.98.58-2.2.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13V45.48v-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.06z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_93_)'
      />
      <linearGradient
        id='banner_svg__SVGID_94_'
        gradientUnits='userSpaceOnUse'
        x1={27.152}
        y1={828.472}
        x2={323.273}
        y2={813.91}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M249.72 122.62v17.28c-.02.43-.25.81-.62 1.03l-60.44 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.24-.64-.68-.62-1.14v-17.21c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04l-.01.03z'
        fill='url(#banner_svg__SVGID_94_)'
      />
      <linearGradient
        id='banner_svg__SVGID_95_'
        gradientUnits='userSpaceOnUse'
        x1={266.472}
        y1={787.556}
        x2={-361.519}
        y2={848.468}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M249.09 121.47l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.35.81-1.12.47-1.72-.11-.19-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_95_)'
      />
      <linearGradient
        id='banner_svg__SVGID_96_'
        gradientUnits='userSpaceOnUse'
        x1={247.406}
        y1={804.733}
        x2={195.944}
        y2={837.386}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M196.76 163.13c-.16 0-.31-.09-.39-.22a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07c-.07.03-.14.05-.22.06z'
        fill='url(#banner_svg__SVGID_96_)'
      />
      <linearGradient
        id='banner_svg__SVGID_97_'
        gradientUnits='userSpaceOnUse'
        x1={248.036}
        y1={805.719}
        x2={196.573}
        y2={838.371}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M240.21 138.07c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.22c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23a.78.78 0 01-.22.04z'
        fill='url(#banner_svg__SVGID_97_)'
      />
      <linearGradient
        id='banner_svg__SVGID_98_'
        gradientUnits='userSpaceOnUse'
        x1={248.126}
        y1={805.857}
        x2={196.663}
        y2={838.51}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M242.2 137.03a.455.455 0 01-.22-.86l.42-.22c.22-.12.49-.04.61.18s.04.49-.18.61h-.01l-.42.22c-.06.05-.13.07-.2.07z'
        fill='url(#banner_svg__SVGID_98_)'
      />
      <linearGradient
        id='banner_svg__SVGID_99_'
        gradientUnits='userSpaceOnUse'
        x1={248.188}
        y1={805.964}
        x2={196.726}
        y2={838.608}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M243.99 136.04a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23a.36.36 0 01-.2.06z'
        fill='url(#banner_svg__SVGID_99_)'
      />
      <linearGradient
        id='banner_svg__SVGID_100_'
        gradientUnits='userSpaceOnUse'
        x1={296.047}
        y1={800.353}
        x2={180.972}
        y2={802.936}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M244.08 106.27v15.74c-.02.38-.23.72-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.19.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_100_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_101_'
          gradientUnits='userSpaceOnUse'
          x1={27.152}
          y1={802.791}
          x2={323.273}
          y2={788.22}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M249.72 96.93v17.28c-.02.42-.25.81-.62 1.02l-60.44 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.4-.23-.64-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.04h-.02v-.01z'
          fill='url(#banner_svg__SVGID_101_)'
        />
        <linearGradient
          id='banner_svg__SVGID_102_'
          gradientUnits='userSpaceOnUse'
          x1={266.474}
          y1={761.888}
          x2={-361.517}
          y2={822.809}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M249.09 95.8l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62 1v.1c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.2.58 3.19 0l60.45-34.9c.6-.34.81-1.1.47-1.7-.1-.21-.27-.37-.47-.48z'
          fill='url(#banner_svg__SVGID_102_)'
        />
        <linearGradient
          id='banner_svg__SVGID_103_'
          gradientUnits='userSpaceOnUse'
          x1={247.409}
          y1={779.059}
          x2={195.946}
          y2={811.702}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M196.76 137.44c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.07a.45.45 0 11.53.73c-.02.02-.05.03-.08.05l-40.01 23.07a.51.51 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_103_)'
        />
        <linearGradient
          id='banner_svg__SVGID_104_'
          gradientUnits='userSpaceOnUse'
          x1={248.001}
          y1={779.987}
          x2={196.539}
          y2={812.639}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M240.21 112.39c-.25.06-.5-.1-.55-.35-.06-.25.1-.5.35-.55l.42-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.07.06-.14.1-.22.11z'
          fill='url(#banner_svg__SVGID_104_)'
        />
        <linearGradient
          id='banner_svg__SVGID_105_'
          gradientUnits='userSpaceOnUse'
          x1={248.129}
          y1={780.186}
          x2={196.667}
          y2={812.829}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M242.2 111.34c-.25 0-.45-.2-.45-.45 0-.17.09-.32.24-.4l.42-.22c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.22c-.07.03-.14.05-.21.05z'
          fill='url(#banner_svg__SVGID_105_)'
        />
        <linearGradient
          id='banner_svg__SVGID_106_'
          gradientUnits='userSpaceOnUse'
          x1={248.194}
          y1={780.288}
          x2={196.732}
          y2={812.94}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M243.99 110.35a.44.44 0 01-.43-.22.46.46 0 01.19-.61l.43-.23a.46.46 0 01.61.19c.11.22.03.49-.19.61l-.42.22c-.06.02-.13.04-.19.04z'
          fill='url(#banner_svg__SVGID_106_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_107_'
        gradientUnits='userSpaceOnUse'
        x1={296.047}
        y1={774.658}
        x2={180.972}
        y2={777.25}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M244.08 80.6v15.74c-.02.38-.23.72-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V80.06c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_107_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_108_'
          gradientUnits='userSpaceOnUse'
          x1={27.152}
          y1={777.402}
          x2={323.273}
          y2={762.84}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M249.72 71.55v17.28c-.01.43-.25.82-.62 1.03l-60.44 34.89c-.99.57-2.2.57-3.19 0L123.81 89.3c-.4-.23-.64-.68-.62-1.14V70.97c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.44-34.9c.37-.22.61-.62.62-1.05z'
          fill='url(#banner_svg__SVGID_108_)'
        />
        <linearGradient
          id='banner_svg__SVGID_109_'
          gradientUnits='userSpaceOnUse'
          x1={266.474}
          y1={736.508}
          x2={-361.517}
          y2={797.429}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M249.09 70.42l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.2.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.03-.48-.22-.92-.63-1.14z'
          fill='url(#banner_svg__SVGID_109_)'
        />
        <linearGradient
          id='banner_svg__SVGID_110_'
          gradientUnits='userSpaceOnUse'
          x1={247.407}
          y1={753.668}
          x2={195.945}
          y2={786.32}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M196.76 112.06c-.25 0-.45-.21-.45-.45 0-.16.09-.3.22-.38l40.01-23.08c.22-.12.49-.04.62.17.12.22.04.49-.17.61L196.98 112c-.06.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_110_)'
        />
        <linearGradient
          id='banner_svg__SVGID_111_'
          gradientUnits='userSpaceOnUse'
          x1={248.03}
          y1={754.652}
          x2={196.567}
          y2={787.295}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M240.21 87a.47.47 0 01-.4-.23.46.46 0 01.19-.61l.42-.23c.22-.12.5-.03.61.19.12.22.03.5-.19.61l-.42.23c-.06.03-.13.05-.21.04z'
          fill='url(#banner_svg__SVGID_111_)'
        />
        <linearGradient
          id='banner_svg__SVGID_112_'
          gradientUnits='userSpaceOnUse'
          x1={248.132}
          y1={754.8}
          x2={196.669}
          y2={787.452}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M242.2 85.96c-.17 0-.33-.09-.41-.24a.445.445 0 01.19-.6l.42-.23c.22-.12.49-.04.61.18v.01c.12.21.04.48-.18.6 0 0-.01 0-.01.01l-.42.23c-.06.02-.13.04-.2.04z'
          fill='url(#banner_svg__SVGID_112_)'
        />
        <linearGradient
          id='banner_svg__SVGID_113_'
          gradientUnits='userSpaceOnUse'
          x1={248.194}
          y1={754.907}
          x2={196.732}
          y2={787.559}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M243.99 84.97c-.17 0-.33-.09-.41-.24a.457.457 0 01.19-.6l.43-.22a.447.447 0 01.41.79l-.42.22c-.06.03-.13.05-.2.05z'
          fill='url(#banner_svg__SVGID_113_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_114_'
        gradientUnits='userSpaceOnUse'
        x1={296.047}
        y1={749.283}
        x2={180.972}
        y2={751.866}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M244.08 55.22v15.72c-.02.38-.23.72-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.21-.59-.62-.57-1.04v-15.7c0 .41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.04z'
        fill='url(#banner_svg__SVGID_114_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_115_'
          gradientUnits='userSpaceOnUse'
          x1={27.151}
          y1={751.917}
          x2={323.272}
          y2={737.355}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M249.72 46.06v17.26c-.02.43-.25.81-.62 1.03l-60.44 34.92c-.99.57-2.2.57-3.19 0l-61.66-35.46c-.4-.23-.64-.67-.62-1.13v-17.2c0 .45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.22.61-.62.62-1.07z'
          fill='url(#banner_svg__SVGID_115_)'
        />
        <linearGradient
          id='banner_svg__SVGID_116_'
          gradientUnits='userSpaceOnUse'
          x1={-8.039}
          y1={726.553}
          x2={266.752}
          y2={715.582}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M249.72 46.01v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.23-11.63c-.39-.22-.63-.64-.63-1.09v-.1c.03-.41.26-.79.62-.99l60.45-34.9c.99-.57 2.2-.57 3.19 0l61.66 35.45c.2.11.35.27.46.47.11.18.17.39.17.6z'
          fill='url(#banner_svg__SVGID_116_)'
        />
        <linearGradient
          id='banner_svg__SVGID_117_'
          gradientUnits='userSpaceOnUse'
          x1={247.405}
          y1={728.184}
          x2={195.942}
          y2={760.837}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M196.76 86.58c-.16 0-.31-.09-.39-.22a.464.464 0 01.16-.62l40.01-23.07c.22-.12.49-.05.62.16.12.22.04.49-.17.62l-40.01 23.07c-.07.04-.14.06-.22.06z'
          fill='url(#banner_svg__SVGID_117_)'
        />
        <linearGradient
          id='banner_svg__SVGID_118_'
          gradientUnits='userSpaceOnUse'
          x1={248.032}
          y1={729.166}
          x2={196.569}
          y2={761.818}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M240.21 61.52c-.17 0-.32-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23a.42.42 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_118_)'
        />
        <linearGradient
          id='banner_svg__SVGID_119_'
          gradientUnits='userSpaceOnUse'
          x1={248.1}
          y1={729.27}
          x2={196.637}
          y2={761.923}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M242.2 60.48c-.25.06-.5-.09-.56-.34s.09-.5.34-.56l.42-.23a.46.46 0 01.61.19c.12.22.04.49-.18.61h-.01l-.42.23c-.05.05-.12.09-.2.1z'
          fill='url(#banner_svg__SVGID_119_)'
        />
        <linearGradient
          id='banner_svg__SVGID_120_'
          gradientUnits='userSpaceOnUse'
          x1={248.186}
          y1={729.416}
          x2={196.724}
          y2={762.059}
          gradientTransform='translate(0 -673.033)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M243.99 59.49a.455.455 0 01-.22-.86l.43-.23c.22-.11.48-.02.6.19.12.22.04.49-.18.61h-.01l-.42.23a.36.36 0 01-.2.06z'
          fill='url(#banner_svg__SVGID_120_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_121_'
        gradientUnits='userSpaceOnUse'
        x1={92.797}
        y1={1014.54}
        x2={290.637}
        y2={956.066}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M239.79 315.74c0 .19-.06.37-.15.54-.09.19-.24.34-.43.44l-56.48 32.67c-.92.52-2.06.52-2.98 0l-57.58-33.12c-.37-.21-.6-.6-.59-1.02a.139.139 0 010-.09c.01-.11.05-.21.09-.3l1.86-4.48.44-1.09 2.45-5.88 14.85-.15 37.36-21.58c.92-.53 2.06-.53 2.98 0l35.69 20.52 4.2-.06 13.38 1.54 2.55 6.17.44 1.09 1.86 4.51c.04.08.06.19.06.29z'
        fill='url(#banner_svg__SVGID_121_)'
      />
      <path
        className='banner_svg__st124'
        d='M237.88 310.87l-54.86 31.68c-1.1.64-2.46.64-3.56 0l-55.98-32.19.44-1.09 56.12 32.27c.74.43 1.66.43 2.4 0l55.03-31.76.41 1.09z'
      />
      <linearGradient
        id='banner_svg__SVGID_122_'
        gradientUnits='userSpaceOnUse'
        x1={13.593}
        y1={983.6}
        x2={249.655}
        y2={974.179}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M235.03 304.13c0 .17-.05.34-.13.49-.1.17-.24.31-.41.41l-17.38 10.03L198.19 326l-15.64 9.03c-.85.49-1.89.49-2.74 0l-16.67-9.54-18.92-10.88-17.4-9.99c-.34-.19-.54-.55-.54-.93a.139.139 0 010-.09c.03-.35.22-.67.52-.85l51.94-29.98c.85-.49 1.89-.49 2.74 0l53 30.48c.17.1.31.24.4.41.09.13.14.3.15.47z'
        fill='url(#banner_svg__SVGID_122_)'
      />
      <linearGradient
        id='banner_svg__SVGID_123_'
        gradientUnits='userSpaceOnUse'
        x1={130.446}
        y1={991.491}
        x2={249.07}
        y2={957.101}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M210.21 298.35l-28.93-16.63a1.96 1.96 0 00-1.96 0l-28.37 16.39h-8.98v5.6c-.01.02-.01.04 0 .06-.01.28.14.53.38.66l12.41 7.08 13.46 7.74 11.87 6.82c.6.35 1.34.35 1.94 0l11.13-6.43 13.47-7.78 12.37-7.14c.12-.07.22-.17.29-.29a.73.73 0 00.09-.35v-5.69l-9.17-.04z'
        fill='url(#banner_svg__SVGID_123_)'
      />
      <linearGradient
        id='banner_svg__SVGID_124_'
        gradientUnits='userSpaceOnUse'
        x1={-1.383}
        y1={978.536}
        x2={221.181}
        y2={969.65}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M219.36 298.4c0 .13-.03.25-.09.36a.78.78 0 01-.29.28l-12.37 7.15-13.47 7.77-11.14 6.43c-.6.35-1.34.35-1.94 0l-11.87-6.82-13.42-7.72-12.37-7.1a.74.74 0 01-.38-.66.085.085 0 010-.07c.02-.25.16-.48.37-.61l36.96-21.34a2.01 2.01 0 011.96 0l37.7 21.68c.12.06.22.16.28.28.06.11.08.24.07.37z'
        fill='url(#banner_svg__SVGID_124_)'
      />
      <path
        d='M219.36 298.4c0 .13-.03.25-.09.36a.78.78 0 01-.29.28l-12.37 7.15-13.47 7.77-11.14 6.43c-.6.35-1.34.35-1.94 0l-11.87-6.82-13.42-7.72-12.37-7.1a.74.74 0 01-.38-.66.085.085 0 010-.07c.02-.25.16-.48.37-.61l36.96-21.34a2.01 2.01 0 011.96 0l37.7 21.68c.12.06.22.16.28.28.06.11.08.24.07.37z'
        opacity={0.5}
        fill='#fff'
      />
      <linearGradient
        id='banner_svg__SVGID_125_'
        gradientUnits='userSpaceOnUse'
        x1={-45.254}
        y1={980.034}
        x2={214.437}
        y2={969.657}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M218.47 298.16c0 .12-.03.24-.08.35-.07.11-.16.21-.28.28l-12.09 6.99-13.16 7.6-10.88 6.28c-.59.34-1.32.34-1.91 0l-11.65-6.67-13.16-7.56-12.08-6.99a.713.713 0 01-.38-.65.064.064 0 010-.06c.02-.25.16-.47.37-.59l36.1-20.85c.59-.34 1.31-.34 1.9 0l36.85 21.18c.11.07.21.16.27.28.1.11.16.26.18.41z'
        fill='url(#banner_svg__SVGID_125_)'
      />
      <linearGradient
        id='banner_svg__SVGID_126_'
        gradientUnits='userSpaceOnUse'
        x1={196.661}
        y1={960.007}
        x2={130.14}
        y2={1005.915}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M210.07 298.12a.65.65 0 01-.08.27.53.53 0 01-.21.21l-.13.08-.14.08-9.13 5.26-10.24 5.9-8.45 4.95c-.46.26-1.02.26-1.48 0l-9.01-5.18-10.23-5.82-9.07-5.3-.21-.12-.12-.07a.596.596 0 01-.29-.51c.01-.19.12-.36.28-.45l28.09-16.32a1.52 1.52 0 011.49 0l28.64 16.47c.09.06.16.13.21.22.06.1.08.22.08.33z'
        fill='url(#banner_svg__SVGID_126_)'
      />
      <linearGradient
        id='banner_svg__SVGID_127_'
        gradientUnits='userSpaceOnUse'
        x1={74.784}
        y1={994.37}
        x2={232.155}
        y2={960.726}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M209.51 298.79l-9.13 5.26-10.24 5.9-8.45 4.93c-.46.26-1.02.26-1.48 0l-9.01-5.18-10.23-5.82-9.07-5.3 27.82-16.07c.46-.27 1.03-.27 1.49 0l28.3 16.28z'
        fill='url(#banner_svg__SVGID_127_)'
      />
      <path
        d='M200.56 298.1c.36.21.48.68.27 1.04a.87.87 0 01-.27.27l-5.52 3.19-7.46 4.3-6.15 3.56c-.34.18-.74.18-1.08 0l-6.57-3.73-7.44-4.29-5.49-3.16a.763.763 0 01-.27-1.04c.07-.11.16-.2.27-.27l19.13-11.05c.34-.2.76-.2 1.09 0l19.49 11.18z'
        opacity={0.7}
        fill='none'
        stroke='#fff'
        strokeMiterlimit={10}
      />
      <linearGradient
        id='banner_svg__SVGID_128_'
        gradientUnits='userSpaceOnUse'
        x1={186.32}
        y1={996.833}
        x2={192.6}
        y2={996.833}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_128_)' d='M186.32 323.59l3.48 2.05 2.8-1.56-3.46-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_129_'
        gradientUnits='userSpaceOnUse'
        x1={192.97}
        y1={992.998}
        x2={199.25}
        y2={992.998}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_129_)' d='M192.97 319.75l3.46 2.05 2.82-1.55-3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_130_'
        gradientUnits='userSpaceOnUse'
        x1={199.61}
        y1={989.168}
        x2={205.9}
        y2={989.168}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_130_)' d='M199.61 315.92l3.47 2.05 2.82-1.55-3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_131_'
        gradientUnits='userSpaceOnUse'
        x1={206.26}
        y1={985.318}
        x2={212.54}
        y2={985.318}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_131_)' d='M206.26 312.07l3.47 2.05 2.81-1.55-3.46-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_132_'
        gradientUnits='userSpaceOnUse'
        x1={212.91}
        y1={981.488}
        x2={219.19}
        y2={981.488}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_132_)' d='M212.91 308.24l3.46 2.05 2.82-1.55-3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_133_'
        gradientUnits='userSpaceOnUse'
        x1={-116.484}
        y1={996.878}
        x2={-110.195}
        y2={996.878}
        gradientTransform='matrix(-1 0 0 1 59.313 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_133_)' d='M175.79 323.63l-3.46 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_134_'
        gradientUnits='userSpaceOnUse'
        x1={-109.837}
        y1={993.028}
        x2={-103.56}
        y2={993.028}
        gradientTransform='matrix(-1 0 0 1 59.313 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_134_)' d='M169.15 319.78l-3.47 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_135_'
        gradientUnits='userSpaceOnUse'
        x1={-103.187}
        y1={989.198}
        x2={-96.907}
        y2={989.198}
        gradientTransform='matrix(-1 0 0 1 59.313 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_135_)' d='M162.5 315.95l-3.47 2.05-2.81-1.55 3.46-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_136_'
        gradientUnits='userSpaceOnUse'
        x1={-96.546}
        y1={985.358}
        x2={-90.257}
        y2={985.358}
        gradientTransform='matrix(-1 0 0 1 59.313 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_136_)' d='M155.85 312.11l-3.46 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_137_'
        gradientUnits='userSpaceOnUse'
        x1={-89.897}
        y1={981.528}
        x2={-83.62}
        y2={981.528}
        gradientTransform='matrix(-1 0 0 1 59.313 -673.033)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path fill='url(#banner_svg__SVGID_137_)' d='M149.21 308.28l-3.47 2.05-2.82-1.55 3.47-2.12z' />
      <linearGradient
        id='banner_svg__SVGID_138_'
        gradientUnits='userSpaceOnUse'
        x1={180.675}
        y1={1164.243}
        x2={180.675}
        y2={905.868}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
        <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
      </linearGradient>
      <path
        d='M209.78 235.59l-28.64-16.47a1.52 1.52 0 00-1.49 0l-28.08 16.22c-.14.14-.24.31-.29.5v61.97c0 .21.11.41.29.51l.12.07.21.12 9.07 5.22 10.23 5.82 9.01 5.32c.46.26 1.02.26 1.48 0l8.45-4.89 10.24-5.9 9.13-5.26.14-.08.13-.08a.53.53 0 00.21-.21c.05-.08.08-.17.08-.27v-62.05a2.35 2.35 0 00-.29-.54z'
        fill='url(#banner_svg__SVGID_138_)'
      />
      <linearGradient
        id='banner_svg__SVGID_139_'
        gradientUnits='userSpaceOnUse'
        x1={181.04}
        y1={1122.62}
        x2={181.04}
        y2={919.933}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
        <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
      </linearGradient>
      <path
        d='M203.89 249.07l-22.47-12.92c-.36-.21-.81-.21-1.17 0l-22.03 12.73c-.11.11-.19.24-.23.39v48.61c0 .17.09.32.23.4l.09.05.16.09 7.12 4.09 8.02 4.57 7.07 4.18c.36.2.8.2 1.16 0l6.63-3.84 8.03-4.63 7.16-4.13.11-.06.1-.06c.07-.04.13-.09.16-.16.04-.06.06-.14.06-.21v-48.68c-.03-.15-.11-.3-.2-.42z'
        fill='url(#banner_svg__SVGID_139_)'
      />
      <linearGradient
        id='banner_svg__SVGID_140_'
        gradientUnits='userSpaceOnUse'
        x1={181.06}
        y1={1082.362}
        x2={181.06}
        y2={926.068}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
        <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
      </linearGradient>
      <path
        d='M198.66 254.7l-17.32-9.96a.904.904 0 00-.9 0l-16.98 9.81c-.09.08-.15.19-.18.3v37.48c0 .13.07.25.18.31l.07.04.13.07 5.49 3.16 6.19 3.52 5.45 3.22c.28.16.62.16.89 0l5.11-2.96 6.19-3.57 5.52-3.18.08-.05.08-.05c.05-.03.1-.07.13-.13.03-.05.05-.1.05-.16v-37.53c-.05-.11-.11-.22-.18-.32z'
        fill='url(#banner_svg__SVGID_140_)'
      />
      <linearGradient
        id='banner_svg__SVGID_141_'
        gradientUnits='userSpaceOnUse'
        x1={177.338}
        y1={874.81}
        x2={178.011}
        y2={968.558}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M177.71 300.85c-.28 0-.51-.23-.51-.51V206.5c-.04-.28.16-.54.44-.57s.54.16.57.44c.01.04.01.09 0 .14v93.84c0 .27-.22.49-.5.5z'
        fill='url(#banner_svg__SVGID_141_)'
      />
      <linearGradient
        id='banner_svg__SVGID_142_'
        gradientUnits='userSpaceOnUse'
        x1={181.188}
        y1={877.054}
        x2={181.861}
        y2={970.803}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M181.55 303.09c-.28 0-.5-.23-.5-.51v-93.84c-.04-.28.16-.54.44-.57.28-.04.54.16.57.44.01.04.01.09 0 .14v93.84c0 .27-.22.5-.51.5z'
        fill='url(#banner_svg__SVGID_142_)'
      />
      <linearGradient
        id='banner_svg__SVGID_143_'
        gradientUnits='userSpaceOnUse'
        x1={184.621}
        y1={889.144}
        x2={185.218}
        y2={971.157}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M184.95 302.78c-.25 0-.45-.2-.45-.45v-82.09c0-.25.2-.45.45-.45s.44.2.45.45v82.09c-.01.25-.21.45-.45.45z'
        fill='url(#banner_svg__SVGID_143_)'
      />
      <linearGradient
        id='banner_svg__SVGID_144_'
        gradientUnits='userSpaceOnUse'
        x1={195.046}
        y1={877.566}
        x2={160.191}
        y2={823.295}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M243.27 227.22c0 3.56-1.27 6.11-3.4 7.4l-16.8 9.58c1.93-1.36 3.08-3.82 3.08-7.19 0-8.12-6.59-18.51-14.69-23.27-.33-.18-.65-.35-.97-.51-2.79-11.03-11.5-22.97-21.82-28.99a29.85 29.85 0 00-5.75-2.63c-3.98-1.28-7.52-1.26-10.33-.09-1.37.58-2.59 1.45-3.58 2.56-3.96-1.82-7.5-2.05-10.18-.89a7.624 7.624 0 00-4.12 4.45c-.28-.16-.52-.37-.78-.53-3.35-1.94-6.37-2.25-8.42-1.11l17.05-9.74c1.9-1.1 4.63-.96 7.73.63.25.13.51.28.76.43s.53.36.79.54c.37-1.02.94-1.95 1.68-2.74l.07-.07.09-.1c2.6-2.6 6.89-3.03 11.87-.9.19.07.37.16.56.25.58-.66 1.25-1.24 1.99-1.72.15-.1.3-.2.47-.28l.14-.09c1.5-.81 3.16-1.26 4.87-1.34 3.53-.2 7.73.94 12.22 3.56 10.32 6.01 19.02 17.94 21.82 28.99.33.15.63.31.95.5 8.13 4.8 14.7 15.19 14.7 23.3z'
        fill='url(#banner_svg__SVGID_144_)'
      />
      <linearGradient
        id='banner_svg__SVGID_145_'
        gradientUnits='userSpaceOnUse'
        x1={181.394}
        y1={869.193}
        x2={173.678}
        y2={843.908}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M193.56 170.92c-1.7.08-3.36.53-4.87 1.34l-.14.09c-.16.08-.32.18-.47.28-.74.48-1.4 1.06-1.99 1.72-.19-.09-.37-.17-.56-.25-4.31-1.83-8.09-1.76-10.71 0l-16 9.18-1.05.57s14.41 5.89 14.76 5.53c.18-.17 5.24-3.93 10.38-7.7l10.68-7.85c.06-.97.05-1.94-.03-2.91z'
        fill='url(#banner_svg__SVGID_145_)'
      />
      <linearGradient
        id='banner_svg__SVGID_146_'
        gradientUnits='userSpaceOnUse'
        x1={242.532}
        y1={939.879}
        x2={181.723}
        y2={832.47}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M227.63 203.47l-17.14 9.78-18.53-20.63-20.11-10.64.73-.41 12.97-7.45 2.55-1.45.2-.12.26-.17.14-.09c4.18-2.31 10.28-1.75 17.09 2.23 10.32 5.98 19.01 17.88 21.84 28.95z'
        fill='url(#banner_svg__SVGID_146_)'
      />
      <linearGradient
        id='banner_svg__SVGID_147_'
        gradientUnits='userSpaceOnUse'
        x1={9.122}
        y1={886.104}
        x2={296.231}
        y2={886.104}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M211.46 213.76c-.33-.19-.65-.35-.97-.51-2.8-11.04-11.5-22.97-21.82-28.99-8.42-4.9-15.73-4.6-19.69-.14-6.76-3.1-12.28-1.6-14.27 3.56-.28-.16-.54-.37-.79-.53-3.35-1.95-6.36-2.25-8.42-1.11-1.75.95-2.82 2.91-2.82 5.78 0 6.2 5.05 14.16 11.24 17.77l57.54 33.55c4.19 2.45 8 2.91 10.66 1.62.33-.17.65-.35.95-.56 1.92-1.36 3.08-3.82 3.08-7.19 0-8.12-6.6-18.51-14.69-23.25z'
        fill='url(#banner_svg__SVGID_147_)'
      />
      <linearGradient
        id='banner_svg__SVGID_148_'
        gradientUnits='userSpaceOnUse'
        x1={238.555}
        y1={930.215}
        x2={218.186}
        y2={863.428}
        gradientTransform='translate(0 -673.033)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M243.27 227.22c0 3.56-1.27 6.11-3.4 7.4l-16.8 9.58c1.93-1.36 3.08-3.82 3.08-7.19 0-8.12-6.59-18.51-14.69-23.27-.33-.18-.65-.35-.97-.51l17.14-9.78c.33.15.63.31.95.5 8.12 4.77 14.69 15.16 14.69 23.27z'
        fill='url(#banner_svg__SVGID_148_)'
      />
      <text
        transform='rotate(30.609 -293.843 403.112)'
        className='banner_svg__st124'
        fontSize={21.628}
        fontFamily='OpenSans-Bold'
      >
        {'FTP'}
      </text>
    </g>
  </svg>
)

export default SvgBanner
