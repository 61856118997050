import React from 'react'
import intl from 'react-intl-universal'
import { CommonBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Button, Wrapper, H4, Link } from '@raysync/common/components'
import DocumentCard from '../../components/document-cards'
import { LOCALES, Navigation } from '@raysync/common/services'

import Layout from '../../components/layout'
import {
  DemandSection,
  FeatureSection,
  ButtonContainer,
  ArchSection,
  FtpExampleSection,
  ExampleContent,
  FtpExampleContainer,
} from './product-sdk.atoms'
import List from '../../components/list'

import Example from '../../images/products/ftp/example.png'
import Logo from '../../images/products/ftp/oppo.png'

import Banner from '@raysync/common/components/svg/products/ftp/banner'

import Feature1 from '@raysync/common/components/svg/products/ftp/feature1'
import Feature2 from '@raysync/common/components/svg/products/ftp/feature2'
import Feature3 from '@raysync/common/components/svg/products/ftp/feature3'
import Feature4 from '@raysync/common/components/svg/products/ftp/feature4'
import Feature5 from '@raysync/common/components/svg/products/ftp/feature5'

import Arch from '@raysync/common/components/svg/products/ftp/arch'
import ArchEn from '../../images/products/ftp/arch_en.svg'
class ProductFTP extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('product4.feature.item1.title'),
        desc: intl.get('product4.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('product4.feature.item2.title'),
        desc: intl.get('product4.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('product4.feature.item3.title'),
        desc: intl.get('product4.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('product4.feature.item4.title'),
        desc: intl.get('product4.feature.item4.desc'),
        svg: <Feature4 />,
      },
      {
        title: intl.get('product4.feature.item5.title'),
        desc: intl.get('product4.feature.item5.desc'),
        svg: <Feature5 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList } = this.state
    const ArchPic = {
      [LOCALES.ZH]: <Arch />,
      [LOCALES.EN]: <img src={ArchEn} alt='arch' />,
      [LOCALES.JA]: <img src={ArchEn} alt='arch' />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='product4' location={location}>
        <Swiper>
          <CommonBanner type='product4' pic={<Banner />} right={35} />
        </Swiper>
        <DemandSection>
          <CommonTitle bottom={35}>{intl.get('product4.needs')}</CommonTitle>
          <p>{intl.get('product4.needs.desc1')}</p>
          <p>{intl.get('product4.needs.desc2')}</p>
        </DemandSection>
        <FeatureSection>
          <Wrapper>
            <CommonTitle bottom={44}>{intl.get('product4.feature.title')}</CommonTitle>
            <List data={featureList} />
            <ButtonContainer>
              <Button variant='outlined' onClick={() => Navigation.toApply({ type: 'prompt', pageType: 'product4' })}>
                {intl.get('product4.feature.button1')}
              </Button>
              <Button onClick={() => Navigation.toApply({ type: 'apply', pageType: 'product4' })}>
                {intl.get('product4.feature.button2')}
              </Button>
            </ButtonContainer>
          </Wrapper>
        </FeatureSection>
        <ArchSection>
          <CommonTitle>{intl.get('product4.deploy.title')}</CommonTitle>
          {ArchPic}
        </ArchSection>
        <FtpExampleSection>
          <Wrapper>
            <CommonTitle bottom={45}>{intl.get('product4.example.title')}</CommonTitle>
            <FtpExampleContainer>
              <div>
                <img src={Example} alt='Example pic' />
              </div>
              <ExampleContent>
                <img src={Logo} alt='Renderbus Logo' />
                <H4>{intl.get('product4.example.desc1')}</H4>
                <p>{intl.get('product4.example.desc2')}</p>
                <p>{intl.get('product4.example.desc3')}</p>
              </ExampleContent>
            </FtpExampleContainer>
            <ButtonContainer>
              <Link to={'/example'}>
                <Button variant='outlined' style={{ margin: '0 auto' }}>
                  {intl.get('product4.example.button')}
                </Button>
              </Link>
            </ButtonContainer>
          </Wrapper>
        </FtpExampleSection>
        <Wrapper style={{ paddingTop: '68px', paddingBottom: '68px' }}>
          <CommonTitle>{intl.get('app.document.title')}</CommonTitle>
          <DocumentCard type='product4' />
        </Wrapper>
        <Swiper>
          <InviteBanner type='product4' />
        </Swiper>
      </Layout>
    )
  }
}

export default ProductFTP
