import React from 'react'

const SvgFeature1 = props => (
  <svg id='feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature1_svg__st0{fill:#334966}.feature1_svg__st1{fill:#fd5c1f}'}</style>
    <g id='feature1_svg__\u7EDF\u4E00\u76D1\u63A7-48x48'>
      <path id='feature1_svg__Rectangle-16_1_' className='feature1_svg__st0' d='M3 6v32h44V6H3zM1 4h48v36H1V4z' />
      <path id='feature1_svg__Rectangle-17_2_' className='feature1_svg__st0' d='M13 44h24v2H13z' />
      <path id='feature1_svg__Rectangle-73_2_' className='feature1_svg__st0' d='M21 38h2v8h-2z' />
      <path id='feature1_svg__Rectangle-73-Copy_1_' className='feature1_svg__st0' d='M27 38h2v8h-2z' />
      <path id='feature1_svg__Rectangle-74_3_' className='feature1_svg__st1' d='M3 15h44v4H3z' />
      <path id='feature1_svg__Rectangle-74-Copy_3_' className='feature1_svg__st1' d='M3 27h44v4H3z' />
      <path
        id='feature1_svg__Path-5_1_'
        className='feature1_svg__st0'
        d='M16.2 15.2L12.68 24H2v-2h9.32l4.48-11.2 10.17 16.27 6.88-11.81L38.47 22H48v2H37.53l-4.38-5.26-7.12 12.19z'
      />
    </g>
  </svg>
)

export default SvgFeature1
