import React from 'react'

const SvgArch = props => (
  <svg id='arch_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 508.44 378.07' xmlSpace='preserve' {...props}>
    <style>
      {
        '.arch_svg__st1{font-family:&apos;SourceHanSansCN-Normal-GBpc-EUC-H&apos;}.arch_svg__st2{font-size:14px}.arch_svg__st3{fill:#8796aa}.arch_svg__st4{font-size:18px}.arch_svg__st5{fill:#fd5c1f}.arch_svg__st6{fill:#252a3a}.arch_svg__st8{fill:#18c631}.arch_svg__st9{fill:#334966}'
      }
    </style>
    <path fill='none' stroke='#8796aa' strokeMiterlimit={10} d='M3.02 4.96h500v200h-500z' />
    <text transform='translate(26.048 50.768)'>
      <tspan x={0} y={0} className='arch_svg__st1 arch_svg__st2'>
        {'\u7528\u6237\u5DF2\u6709\u7684'}
      </tspan>
      <tspan x={1.64} y={16.8} className='arch_svg__st1 arch_svg__st2'>
        {'FTP\u670D\u52A1\u5668'}
      </tspan>
    </text>
    <text transform='translate(410.048 50.767)'>
      <tspan x={0} y={0} className='arch_svg__st1 arch_svg__st2'>
        {'\u956D\u901F'}
      </tspan>
      <tspan x={0} y={16.8} className='arch_svg__st1 arch_svg__st2'>
        {'\u52A0\u901F\u4EE3\u7406'}
      </tspan>
    </text>
    <text transform='translate(118.012 351.93)'>
      <tspan x={0} y={0} className='arch_svg__st1 arch_svg__st2'>
        {'\u666E\u901A'}
      </tspan>
      <tspan x={-19.36} y={18.8} className='arch_svg__st1 arch_svg__st2'>
        {'FTP\u5BA2\u6237\u7AEF'}
      </tspan>
    </text>
    <text transform='translate(352.012 351.93)'>
      <tspan x={0} y={0} className='arch_svg__st1 arch_svg__st2'>
        {'\u956D\u901F\u52A0\u901F'}
      </tspan>
      <tspan x={-5.36} y={18.8} className='arch_svg__st1 arch_svg__st2'>
        {'FTP\u5BA2\u6237\u7AEF'}
      </tspan>
    </text>
    <text transform='translate(146.13 169.93)' className='arch_svg__st3 arch_svg__st1 arch_svg__st4'>
      {'FTP'}
    </text>
    <text transform='translate(392.048 169.93)' className='arch_svg__st3 arch_svg__st1 arch_svg__st4'>
      {'\u956D\u901F\u534F\u8BAE'}
    </text>
    <path
      className='arch_svg__st5'
      d='M138.82 46.62v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69V45.1c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.02-5.79-.41-8.03-1.14zM135.69 35.37c.03 1.74 4.79 3.68 11.15 3.68 6.38 0 11.16-1.95 11.16-3.69s-4.77-3.69-11.16-3.69c-6.38 0-11.14 1.94-11.15 3.69v.01zM112 60.76v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.02-12.67-2.94z'
    />
    <path
      className='arch_svg__st5'
      d='M112 53.04v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98-.01-10.19-1.02-12.67-2.94zM138.82 53.82v5.64c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69V52.3c-2.27 1.74-6.8 2.66-11.15 2.66-2.99-.01-5.79-.41-8.03-1.14z'
    />
    <path
      className='arch_svg__st5'
      d='M112 45.31v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.01-12.67-2.94zM146.84 40.53c-4.35 0-8.88-.92-11.15-2.65v1.35c1.91.89 3.13 2.07 3.13 3.53v2.28c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69v-4.69c-2.27 1.73-6.8 2.65-11.16 2.65zM138.82 61.03v5.63c2.14.77 5.02 1.22 8.03 1.22 6.38 0 11.15-1.95 11.15-3.69V59.5c-2.27 1.74-6.8 2.66-11.15 2.66-2.99 0-5.79-.4-8.03-1.13zM112 68.48v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94-4.98 0-10.19-1.02-12.67-2.94z'
    />
    <path
      className='arch_svg__st5'
      d='M124.67 38.75c-7.25 0-12.67 2.12-12.67 4.01 0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01c0-1.9-5.42-4.01-12.67-4.01z'
    />
    <g>
      <path
        className='arch_svg__st5'
        d='M391.43 34.46h-30.74c-2.5 0-4.51 1.97-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h30.74c2.46 0 4.51-1.93 4.51-4.33 0-2.4-2.06-4.33-4.51-4.33zm-18.04 5.34h-12v-2.67h12v2.67zm6.23.34c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48 0 .78-.69 1.48-1.54 1.48zm5.48 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.05.78-.64 1.48-1.54 1.48zm5.31 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.05.78-.68 1.48-1.54 1.48zM391.43 44.46h-30.74c-2.46 0-4.51 1.93-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h30.74c2.46 0 4.51-1.93 4.51-4.33 0-2.4-2.06-4.33-4.51-4.33zm-18.04 6h-12V47.8h12v2.66zm6.31.31c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.05.82-.68 1.48-1.54 1.48zm5.53 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48s-.68 1.48-1.54 1.48zm5.31 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48s-.68 1.48-1.54 1.48zM391.6 63.13c2.46 0 4.51-1.93 4.51-4.33 0-2.36-2.01-4.33-4.51-4.33h-30.74c-2.46 0-4.51 1.93-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h14.53v4h-2v2.67h-16v2.67h16v2h6.67v-2h14.67V69.8h-14.67v-2.67h-2v-4h13.54zm-.97-6.16c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48-1.54-.66-1.54-1.48.68-1.48 1.54-1.48zm-5.32 0c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48-1.54-.66-1.54-1.48.69-1.48 1.54-1.48zm-5.48 0c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48c-.86 0-1.54-.66-1.54-1.48-.05-.82.64-1.48 1.54-1.48zm-6.44 2.83h-12v-2.67h12v2.67z'
      />
    </g>
    <g>
      <path
        className='arch_svg__st5'
        d='M381.62 281.45c-5.35 0-10.68 1.03-15.4 2.18l3.48 2.5c-4.69.88-9.49.62-12.13.04v10.26c3.57.95 15.32.25 20.49-1.86l-1.61-1.95c1.9-.19 3.18-.23 5.17-.23 5.49 0 10.7.76 15.4 2.13v-10.89c-4.46-1.09-10.05-2.18-15.4-2.18z'
      />
      <path
        className='arch_svg__st6'
        d='M397.95 321.22c-3.59.37-6.11.95-8.65 1.69v3.02c-3.97.89-10.4 1.78-15.57 1.45-5.34-.34-12.24-2.27-15.22-3.4v-10.84c4.22 2.24 12.2 3.76 18.78 3.76 3.67 0 8.16-.48 10.81-1.03l-.52-2.7c3.37-1.15 5.46-2.13 10.37-2.62v10.67zM391.22 296.6c-3.74 0-9.21.86-10.75 1.29l1.79 2.86c-4.02 1.01-8.04 1-10.99 1-6.91 0-12.79-1.46-18.89-3.78v10.86c4.69 1.59 10.71 2.84 16.43 2.84s11.97-.78 17.33-2.63l-.5-1.32c1.86-.23 3.45-.35 5.49-.35 3.84 0 7.07.14 11.08 1.09v-10.89c-2.22-.49-7.25-.97-10.99-.97z'
      />
    </g>
    <g>
      <path className='arch_svg__st5' d='M133.02 99.96v166M132.02 99.96h2v166h-2z' />
      <path className='arch_svg__st5' d='M127.02 100.38l6-8 6 8' />
      <g>
        <path className='arch_svg__st5' d='M139.02 265.38l-6 8-6-8' />
      </g>
    </g>
    <g>
      <path className='arch_svg__st5' d='M333 54.96H178M178 53.96h155v2H178z' />
      <path className='arch_svg__st5' d='M332.58 48.96l8 6-8 6' />
      <g>
        <path className='arch_svg__st5' d='M178.58 60.96l-8-6 8-6' />
      </g>
    </g>
    <g>
      <path fill='none' stroke='#18c631' strokeWidth={2} strokeMiterlimit={10} d='M378.02 98.96v166' />
      <path className='arch_svg__st8' d='M372.02 99.38l6-8 6 8M384.02 264.38l-6 8-6-8' />
    </g>
    <g id='arch_svg__\u7075\u6D3B\u591A\u7AEF'>
      <path id='arch_svg__Combined-Shape_22_' className='arch_svg__st9' d='M157 289.45h-2v-4h-44v32h20v2h-22v-36h48z' />
      <path id='arch_svg__Rectangle-17' className='arch_svg__st9' d='M121 323.45h10v2h-10z' />
      <path id='arch_svg__Rectangle-73' className='arch_svg__st9' d='M129 317.45h2v8h-2z' />
      <path id='arch_svg__Rectangle-77' fill='none' stroke='#334966' strokeWidth={2} d='M136 294.45h20v30h-20z' />
      <path id='arch_svg__Rectangle_2_' className='arch_svg__st9' d='M136 317.45h21v2h-21z' />
      <path id='arch_svg__Rectangle-Copy' className='arch_svg__st5' d='M139 297.45h16v20h-16z' />
      <path id='arch_svg__Rectangle-Copy-2' className='arch_svg__st5' d='M117 293.45h12v2h-12z' />
      <path id='arch_svg__Rectangle-Copy-3' className='arch_svg__st5' d='M117 299.45h12v2h-12z' />
      <path id='arch_svg__Rectangle-Copy-4' className='arch_svg__st5' d='M117 305.45h12v2h-12z' />
    </g>
  </svg>
)

export default SvgArch
